import {
  AdminAppointmentTimeTypeEnum,
  AppointmentsPerDaysDto,
  EmployeeAppointmentSlotDto,
  SelectedAppointmentDto,
} from "../../services/admin/adminTypes";
import { AppState } from "../store";

export const adminHasTokenSelector = (state: AppState): boolean => {
  return state.adminData.admin.token !== "";
};

export const adminTokenSelector = (state: AppState): string => {
  return state.adminData.admin.token;
};

export const adminStatusSelector = (state: AppState): string => {
  return state.adminData.admin.adminStatus;
};

export const isDataLoadingSelector = (state: AppState): boolean => {
  return state.adminData.appointments.loadingAppointmentData;
};

export const chosenDayIndexSelector = (state: AppState): number => {
  return state.adminData.appointments.dayIndex;
};

export const dayAppointmentsSelector = (
  state: AppState
): AppointmentsPerDaysDto[] => {
  return state.adminData.appointments.appointmentsPerDays;
};

export const chosenTimeIndexSelector = (state: AppState): number => {
  return state.adminData.appointments.timeIndex;
};

export const TimesInSelectedDaySelector = (
  state: AppState
): EmployeeAppointmentSlotDto[] | [] => {
  try {
    return state.adminData.appointments.appointmentsPerDays[
      state.adminData.appointments.dayIndex
    ].appointmentSlotDtos;
  } catch {
    return [];
  }
};

export const isUserDataFormValidSelector = (
  state: AppState
): { name: boolean; phone: boolean; email: boolean } => {
  const isNameValid =
    state.adminData.appointments.customerForm.name.length >= 3;

  const isUserDataFormValidObject = { name: false, phone: true, email: true };

  if (isNameValid) {
    isUserDataFormValidObject.name = true;
  }
  return isUserDataFormValidObject;
};

export const isAppointmentReservedSelector = (state: AppState): boolean => {
  return !!state.adminData.appointments.appointmentsPerDays[
    chosenDayIndexSelector(state)
  ]?.appointmentSlotDtos[chosenTimeIndexSelector(state)]?.appointmentContractId;
};

export const AdminAppointmentTimeTypeSelector = (
  state: AppState
): AdminAppointmentTimeTypeEnum => {
  const appointment =
    state.adminData.appointments.appointmentsPerDays[
      chosenDayIndexSelector(state)
    ]?.appointmentSlotDtos[chosenTimeIndexSelector(state)];

  return appointment?.appointmentSlotStatus;
};

export const customerFormSelector = (
  state: AppState
): SelectedAppointmentDto => {
  return state.adminData.appointments.customerForm;
};

export const reservedAppointmentDataSelector = (
  state: AppState
): SelectedAppointmentDto => {
  return state.adminData.appointments.reservedAppointmentData;
};

export const userTokenSelector = (state: AppState): string => {
  return state.adminData.appointments.appointmentsPerDays[
    chosenDayIndexSelector(state)
  ]?.appointmentSlotDtos[chosenTimeIndexSelector(state)]?.appointmentContractId;
};

export const hasTimesInChosenDaySelector = (state: AppState): boolean => {
  return (
    state.adminData.appointments.appointmentsPerDays[
      chosenDayIndexSelector(state)
    ]?.appointmentSlotDtos.length > 0
  );
};
