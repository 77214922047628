interface SmallHeadingProps {
  style?: string;
  children: React.ReactNode;
}

const SmallHeading: React.FunctionComponent<SmallHeadingProps> = (props) => {
  return (
    <h4
      className={
        "text-lg font-bold font-roboto desktop:text-2xl pb-4 leading-lnh21 desktop:leading-lnh28" +
        props.style
      }
      style={{ fontWeight: 700 }}
    >
      {props.children}
    </h4>
  );
};

export default SmallHeading;
