import { useEffect, useState } from "react";
import SmallHeading from "./SmallHeading";

interface InputProps {
  data: {
    inputText: string;
    type: string;
    placeholder: string;
  };
  forbidden?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  requiredField: {
    required: boolean;
    validField: boolean;
    formTouched: boolean;
  };
  value?: string;
}

export enum InputStyleEnum {
  normal = "",
  forbidden = "text-darkgrey",
  error = "text-error",
}

const Input: React.FunctionComponent<InputProps> = (props) => {
  const [touchedInput, setTouchedInput] = useState(false);
  const [text, setText] = useState(props.value);

  useEffect(() => {
    setText(props.value);
  }, [props.value]);

  const inputTouched =
    touchedInput || props.requiredField.formTouched ? true : false;

  let inputTextStyle = "";
  let inputStyle = "";

  if (!props.requiredField.validField && inputTouched) {
    inputTextStyle = InputStyleEnum.error;
    inputStyle = "border-error";
  } else {
    inputTextStyle = InputStyleEnum.normal;
    inputStyle = "border-lightgrey";
  }

  return (
    <div className={"desktop:w-1/2" + inputTextStyle}>
      <SmallHeading style="pb-2 pt-3 desktop:pb-4 flex">
        {props.data.inputText}
        {props.requiredField.required ? (
          <p className="ml-3 text-error leading-lnh21 desktop:leading-lnh28">
            *
          </p>
        ) : null}
      </SmallHeading>

      <input
        placeholder={props.data.placeholder}
        type={props.data.type}
        className={
          props.data.type === "email"
            ? "border w-full text-lg font-light p-2.5 desktop:text-2xl leading-lnh21 desktop:leading-lnh28 border-lightgrey "
            : "border w-full text-lg font-light p-2.5 desktop:text-2xl leading-lnh21 desktop:leading-lnh28 " +
              inputStyle
        }
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          props.onChange(event);
          setTouchedInput(true);
          setText(event.target.value);
        }}
        value={text}
      />
      {!props.requiredField.validField && inputTouched ? (
        <p className="text-error">Molimo Vas, unesite podatke</p>
      ) : null}
      {props.data.type === "email" && (
        <p className="text-[#6b6b6b] font-bold">
          Poslaćemo vam notifikaciju i podsjetnik za zakazan termin ukoliko
          unesete email
        </p>
      )}
    </div>
  );
};

export default Input;
