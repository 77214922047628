import { GetStaticProps, NextPage } from "next";
import { useDispatch } from "react-redux";
import Input from "../appointment-form/Input";
import Button from "../components/Button";
import Subtitle from "../components/Subtitle";
import Title from "../components/Title";

import fetchStrapiPage from "../lib/fetchStrapiPage";
import PageCollection from "../strapi_types/PageCollection";
import Link from "next/link";
import { loginAdmin } from "../appointment-redux/admin/extraReducers/loginAdmin";
import { useAppSelector } from "../appointment-redux/store";
import { adminStatusSelector } from "../appointment-redux/admin/adminSelectors";
import { useEffect, useState } from "react";
import router from "next/router";
import { AdminStatusEnum } from "../services/admin/adminTypes";
import Head from "next/head";

interface HomeProps {
  pageContent: PageCollection;
}

const Login: NextPage = () => {
  const [adminUsername, setAdminUsername] = useState("");
  const [adminPassword, setAdminPassword] = useState("");

  const adminLoginCredentials = useAppSelector(adminStatusSelector);

  const dispatch = useDispatch();

  useEffect(() => {
    if (
      adminLoginCredentials === AdminStatusEnum.Logged ||
      localStorage.getItem("adminToken")
    ) {
      router.push("/admin-appointments");
    }
  }, [adminLoginCredentials]);

  return (
    <>
      <Head>
        <title>Barbershop Tatarević - Login</title>
        <meta name="robots" content="noindex" />
      </Head>
      <div className="px-4 py-6">
        <Title>ULOGUJTE SE</Title>
        <Subtitle style="py-2">
          Unesite Vaš email i lozinku kako bi ste se ulogovali na Vaš admin
          panel.
        </Subtitle>
        <Input
          data={{
            inputText: "Username",
            type: "text",
            placeholder: "Username",
          }}
          onChange={(event) => {
            setAdminUsername(event.target.value);
          }}
          requiredField={{
            required: false,
            validField: true,
            formTouched: false,
          }}
        />
        <Input
          data={{
            inputText: "Password",
            type: "password",
            placeholder: "Password",
          }}
          onChange={(event) => {
            setAdminPassword(event.target.value);
          }}
          requiredField={{
            required: false,
            validField: true,
            formTouched: false,
          }}
        />

        <Button
          buttonTitle="Uloguj se"
          onButtonClick={() => {
            dispatch(
              loginAdmin({
                username: adminUsername,
                password: adminPassword,
              })
            );
          }}
          buttonStyle="mb-4"
        />

        {adminLoginCredentials === AdminStatusEnum.WrongCredentials ? (
          <Subtitle style="font-light text-accent">
            <span className="font-bold text-accent leading-lnh21 desktop:leading-lnh28">
              Email
            </span>{" "}
            ili{" "}
            <span className="font-bold text-accent leading-lnh21 desktop:leading-lnh28">
              lozinka
            </span>{" "}
            nisu tačni.
          </Subtitle>
        ) : null}

        <Subtitle style="mt-4">
          Zaboravili ste lozinku? Resetujte ju{" "}
          <Link href="/recovery">
            <a className="font-bold text-accent">OVDE</a>
          </Link>
          .
        </Subtitle>
      </div>
    </>
  );
};

export const getStaticProps: GetStaticProps<HomeProps> = async () => {
  const data = await fetchStrapiPage("home");
  return {
    props: {
      pageContent: data,
    },
  };
};

export default Login;
